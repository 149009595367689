<template>
  <v-container class="content">
    <title-header :title="title" />
    <v-row>
      <v-col cols="12" lg="4">
        <v-card
          v-if="recycler"
          class="d-flex flex-column justify-space-between info-content mb-10"
        >
          <div>
            <v-card-title
              class="d-flex justify-space-between align-center pt-10"
            >
              <h2>{{ recycler.name }}</h2>
              <pencil-btn @action="editRecycler" />
            </v-card-title>

            <v-card-text>
              <!-- status -->
              <v-row no-gutters>
                <recycler-status-chip
                  class="mr-2"
                  :status="recycler.status.id"
                />
                <recycler-situation-chip :situation="recycler.situation.id" />
              </v-row>

              <!-- CNPJ --->
              <v-row
                v-if="recycler.cnpj"
                class="ma-0 mt-2"
                align="center"
                justify="start"
              >
                <div class="d-flex justify-start align-center">
                  <v-icon class="mr-5" size="18" left
                    >mdi-note-text-outline</v-icon
                  >
                  <p class="ma-0" v-if="recycler.cnpj">
                    {{ recycler.cnpj | VMask(masks.cnpj) }}
                  </p>
                </div>
              </v-row>

              <!-- site -->
              <v-row
                v-if="recycler.site"
                class="ma-0 mt-2"
                align="center"
                justify="start"
              >
                <div class="d-flex justify-start align-center">
                  <v-icon class="mr-5" size="18" left>mdi-earth</v-icon>
                  <p class="ma-0">
                    {{ recycler.site }}
                  </p>
                </div>
              </v-row>
            </v-card-text>
          </div>

          <edit-recycler-dialog
            :key="`edit-recycler-dialog-${editRecyclerDialogKey}`"
            :state="editRecyclerDialogState"
            :recycler="recycler"
            :fnConfirm="handleEditRecycler"
          />
          <edit-residues-dialog
            :key="`edit-residue-dialog-${editResidueDialogKey}`"
            :state="editResiduesDialogState"
            :selectedIds="recycler.residuesIds"
            :fnConfirm="handleEditResidues"
          />

          <div>
            <v-divider />

            <v-card-title
              class="d-flex justify-space-between align-center pt-4"
            >
              <h3>Resíduos</h3>
              <pencil-btn @action="editRecyclerResidues" />
            </v-card-title>

            <!-- resíduos -->
            <template>
              <v-card-text>
                <!-- resíduos -->
                <div
                  v-for="(recyclerGroup, index) in recycler.residues"
                  :key="`recycler-group-${index}`"
                >
                  <h4 class="residue-type">
                    {{ recyclerGroup.residueType }}
                  </h4>
                  <p class="ma-0">
                    {{ recyclerGroup.residueSubtypes.join(", ") }}
                  </p>
                </div>
                <p v-if="!recycler.residues.length" class="ma-0">
                  Não registrado
                </p>
              </v-card-text>
            </template>
          </div>
          <edit-address-dialog
            :key="`edit-address-dialog-${editAddressDialogKey}`"
            :state="editAddressDialogState"
            :fnConfirm="handleEditAddress"
            :address="recycler.address"
          />
          <div v-if="recycler.address">
            <v-divider />

            <v-card-title
              class="d-flex justify-space-between align-center pt-4"
            >
              <h3>UF</h3>
              <pencil-btn @action="editRecyclerUf" />
            </v-card-title>

            <v-card-text>
              <!-- Address -->
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-store</v-icon>
                <address-info
                  :address="recycler.address"
                  color="secondary-text"
                />
              </div>
            </v-card-text>
          </div>

          <edit-recycler-agent-dialog
            :key="`edit-recycler-agent-dialog-${editRecyclerAgentDialogKey}`"
            :state="editRecyclerAgentDialogState"
            :agent="recycler.agent"
            :fnConfirm="handleEditRecyclerAgent"
          />
          <div>
            <v-divider></v-divider>
            <v-card-title
              class="d-flex justify-space-between align-center pt-4"
            >
              <h3>Responsável</h3>
              <pencil-btn @action="editRecyclerAgent" />
            </v-card-title>

            <v-card-text>
              <!-- Responsável -->
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-account</v-icon>
                <p class="ma-0">{{ recycler.recyclerAgent }}</p>
              </div>
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-email</v-icon>
                <p class="ma-0">{{ recycler.email }}</p>
              </div>
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-phone</v-icon>
                <p class="ma-0">{{ recycler.phones }}</p>
              </div>
            </v-card-text>
          </div>
          <div>
            <v-divider></v-divider>
            <v-card-title
              class="d-flex justify-space-between align-center pt-4"
            >
              <h3>Vigência dos Documentos</h3>
            </v-card-title>

            <v-card-text class="pa-1">
              <!-- Documentos -->
              <div
                class="mb-3"
                v-for="(document, index) in recycler.requiredDocumments"
                :key="`recycler-document-${index}`"
              >
                <recycler-required-documents :requiredDocument="document" />
                <v-divider class="ml-1"></v-divider>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8">
        <div class="documents-content">
          <v-card class="pt-5">
            <template v-if="recyclerId">
              <recycler-documents
                :key="`recycler-documents-${updateKey}`"
                :recyclerId="recyclerId"
                @update="handleUpdateData"
              />
            </template>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import _ from "lodash";

  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import masksMixin from "@/modules/core/mixins/masksMixin";
  import PencilBtn from "@/modules/core/views/components/PencilBtn";
  import RecyclerDocuments from "@/modules/recycler/views/components/RecyclerDocuments";
  import RecyclerRequiredDocuments from "@/modules/recycler/views/components/RecyclerRequiredDocuments";
  import EditResiduesDialog from "@/modules/recycler/views/components/EditResiduesDialog";
  import EditAddressDialog from "@/modules/recycler/views/components/EditAddressDialog";
  import EditRecyclerAgentDialog from "@/modules/recycler/views/components/EditRecyclerAgentDialog";
  import RecyclerStatusChip from "@/modules/recycler/views/components/RecyclerStatusChip";
  import RecyclerSituationChip from "@/modules/recycler/views/components/RecyclerSituationChip";
  import AddressInfo from "@/modules/core/views/components/AddressInfo";
  import EditRecyclerDialog from "@/modules/recycler/views/components/EditRecyclerDialog.vue";

  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";
  import { recyclerSituations } from "@/modules/recycler/enums/recyclerSituations.enum";

  import { mapActions } from "vuex";

  export default {
    name: "ViewRecycler",

    components: {
      TitleHeader,
      RecyclerDocuments,
      PencilBtn,
      EditResiduesDialog,
      RecyclerRequiredDocuments,
      EditAddressDialog,
      EditRecyclerAgentDialog,
      RecyclerStatusChip,
      RecyclerSituationChip,
      EditRecyclerDialog,
      AddressInfo
    },

    mixins: [masksMixin],

    data: () => ({
      title: "Perfil do Reciclador",

      recyclerId: undefined,

      editResidueDialogKey: 1,
      editResiduesDialogState: {
        dialog: false
      },

      editAddressDialogKey: 1,
      editAddressDialogState: {
        dialog: false
      },

      editRecyclerAgentDialogKey: 1,
      editRecyclerAgentDialogState: {
        dialog: false
      },

      editRecyclerDialogKey: 1,
      editRecyclerDialogState: {
        dialog: false
      },

      recycler: undefined,

      updateKey: 1
    }),

    async mounted() {
      this.initRecyclerId();
      await this.initViewRecycler();
    },

    watch: {
      "editResiduesDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.editResidueDialogKey++;
        }
      },

      "editAddressDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.editAddressDialogKey++;
        }
      },

      "editRecyclerAgentDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.editRecyclerAgentDialogKey++;
        }
      },

      "editRecyclerDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.editRecyclerDialogKey++;
        }
      }
    },

    methods: {
      ...mapActions("recycler", ["getRecycler", "updateRecycler"]),
      ...mapActions(["toggleSnackbar"]),

      initRecyclerId() {
        this.recyclerId = parseInt(this.$route.params.id, 10);
      },

      handleUpdateData() {
        this.initViewRecycler(true);
      },

      getRecyclerStatus(recycler) {
        return recycler.inactivatedAt
          ? recyclerStatus.INACTIVE
          : recyclerStatus.ACTIVE;
      },

      getRecyclerSituation(recycler) {
        return recycler.isRegular
          ? recyclerSituations.REGULAR
          : recyclerSituations.IRREGULAR;
      },

      async initViewRecycler(incrementKey = false) {
        try {
          const { data } = await this.getRecycler(this.recyclerId);
          this.recycler = this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          if (incrementKey) {
            this.updateKey++;
          }
        }
      },

      formatData(recycler) {
        const phones = recycler.phones
          .map(phone => this.parsePhone(phone))
          .join(", ");

        return {
          ...recycler,
          phones,
          agent: this.formatAgent(recycler),
          residues: this.formatResidues(recycler),
          residuesIds: this.formatResiduesIds(recycler),
          status: this.getRecyclerStatus(recycler),
          situation: this.getRecyclerSituation(recycler)
        };
      },

      formatAgent(recycler) {
        return {
          recyclerAgent: recycler.recyclerAgent,
          email: recycler.email,
          phones: recycler.phones
        };
      },

      formatResiduesIds(recycler) {
        return recycler.residueSubtypes.map(residueSubtypes => ({
          id: residueSubtypes.id
        }));
      },

      formatResidues(recycler) {
        let residues = recycler.residueSubtypes.map(residueSubtype => {
          return {
            residueType: residueSubtype.residueType.description,
            residueSubtypes: residueSubtype.description
          };
        });

        residues = _(residues)
          .groupBy("residueType")
          .map((group, residueType) => {
            return {
              residueType: residueType,
              residueSubtypes: _.map(group, "residueSubtypes")
            };
          })
          .value();

        return residues;
      },

      editRecyclerUf() {
        this.editAddressDialogState.dialog = true;
      },

      editRecyclerResidues() {
        this.editResiduesDialogState.dialog = true;
      },

      editRecyclerAgent() {
        this.editRecyclerAgentDialogState.dialog = true;
      },

      editRecycler() {
        this.editRecyclerDialogState.dialog = true;
      },

      async handleEditResidues(residueSubtypes) {
        await this.handleUpdateRecycler({
          body: {
            residueSubtypes
          },
          successMessage: "Residuo(s) alterado(s) com sucesso"
        });
      },

      async handleUpdateRecycler({ body, successMessage }) {
        try {
          await this.updateRecycler({
            id: this.recyclerId,
            body
          });

          this.toggleSnackbar({
            text: successMessage,
            type: "success"
          });

          this.handleUpdateData();
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async handleEditAddress(address) {
        await this.handleUpdateRecycler({
          body: {
            ...address,
            residueSubtypes: this.recycler.residuesIds
          },
          successMessage: "Endereço alterado com sucesso"
        });
      },

      async handleEditRecyclerAgent(form) {
        await this.handleUpdateRecycler({
          body: {
            ...form,
            residueSubtypes: this.recycler.residuesIds
          },
          successMessage: "Representante alterado com sucesso"
        });
      },

      async handleEditRecycler(form) {
        await this.handleUpdateRecycler({
          body: {
            ...form,
            residueSubtypes: this.recycler.residuesIds
          },
          successMessage: "Reciclador alterado com sucesso"
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    .info-content {
      min-height: 250px;
      min-width: 300px;
    }

    .documents-content {
      height: 100%;
      min-width: 300px;

      .v-card {
        height: inherit;
      }
    }
  }
</style>
