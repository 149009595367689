<template>
  <v-layout fill-height class="d-flex flex-column justify-space-between">
    <div>
      <div class="table-title">
        <div>
          <h5><span class="title">Histórico Documentos</span></h5>
        </div>
      </div>

      <v-divider></v-divider>
      <div class="ma-4 d-flex justify-end">
        <v-btn
          color="secondary-color-1"
          class="next-step-button white--text"
          :block="$isMobile"
          @click="handleNewDocument"
        >
          <v-icon>mdi-plus</v-icon>&nbsp;
          <span>Documentos</span>
        </v-btn>
      </div>
      <v-divider></v-divider>

      <add-document-dialog
        :key="`add-document-dialog-key-${addDocumentDialogKey}`"
        :state="addDocumentDialogState"
        :fnSubmit="handleSubmitDocument"
        :recyclerId="recyclerId"
      />

      <template v-if="documents.length">
        <v-data-table :headers="headers" :items="documents" hide-default-footer>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn text icon color="#607D8B" @click="downloadContract(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </template>
      <div class="pt-10" v-if="!documents.length && !pristine">
        <document-not-found :title="documentNotFound" />
      </div>
    </div>

    <div v-if="hasDocuments">
      <pagination
        :showItemsPerPage="false"
        :currentPage="tableInfo.currentPage"
        :itemsPerPage="tableInfo.itemsPerPage"
        :totalPages="tableInfo.totalPages"
        @currentPageChanged="handleCurrentPageChange($event)"
      />
    </div>
  </v-layout>
</template>

<script>
  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import DownloadMixin from "@/modules/core/mixins/downloadMixin";
  import AddDocumentDialog from "@/modules/recycler/views/components/AddDocumentDialog";
  import DocumentNotFound from "@/modules/core/views/components/DocumentNotFound";

  import { mapActions } from "vuex";
  import { parseISO } from "date-fns";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "RecyclerDocuments",

    props: {
      recyclerId: {
        type: Number,
        required: true
      }
    },

    components: {
      Pagination,
      AddDocumentDialog,
      DocumentNotFound
    },

    mixins: [MasksMixin, TableHandler, DownloadMixin],

    data: () => ({
      headers: [
        { text: "Descrição", value: "description" },
        { text: "Número do documento", value: "number" },
        { text: "Validade", value: "expiresAt" },
        { value: "id", sortable: false }
      ],

      pristine: true,

      documentNotFound: [
        "Ainda não existem documentos cadastrados.",
        "Adicione o primeiro documento."
      ],

      addDocumentDialogKey: 1,
      addDocumentDialogState: {
        dialog: false
      },

      documents: []
    }),

    watch: {
      "addDocumentDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.addDocumentDialogKey++;
        }
      }
    },

    created() {
      this.tableInfo.limit = 8;
    },

    async mounted() {
      await this.fetchDocuments();
    },

    computed: {
      hasDocuments() {
        return !!this.documents?.length;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", [
        "setCreateDocumentItems",
        "getRecyler",
        "getRecyclerDocuments",
        "addDocumentRecycler"
      ]),

      downloadContract(document) {
        if (!document?.file?.hash) {
          return;
        }
        this.executeDownload(document.file.hash);
      },

      updateData() {
        this.fetchDocuments();
      },

      async fetchDocuments() {
        try {
          const { page, limit } = this.getPayloadData();

          const { data, meta } = await this.getRecyclerDocuments({
            id: this.recyclerId,
            params: {
              page,
              limit
            }
          });

          this.documents = this.formatDocuments(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.pristine = false;
        }
      },

      formatDocuments(documents) {
        return documents.map(document => {
          const endAtISOFormat = parseISO(document.expiresAt);

          return {
            ...document,
            description: document.typeDocument.description,
            expiresAt: this.parseDate(endAtISOFormat, "dd MMM yyyy")
          };
        });
      },

      handleNewDocument() {
        this.addDocumentDialogState.dialog = true;
      },

      async handleSubmitDocument(document) {
        await this.saveDocument(document);
      },

      async saveDocument(document) {
        try {
          const payload = this.mountRecyclerDocumentsPayload(document);

          await this.addDocumentRecycler(payload);

          this.toggleSnackbar({
            text: "Documentos adicionados com sucesso!",
            type: "success"
          });

          this.$emit("update");
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              `Ocorreu um erro: ${error}, tente novamente`,
            type: "error"
          });
        }
      },

      mountRecyclerDocumentsPayload(document) {
        return {
          payload: { documents: [document] },
          id: this.recyclerId
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .table-title {
    margin-left: 32px;
  }

  .title {
    border-bottom: 2px solid green;
    padding-bottom: 5px;
  }
</style>
