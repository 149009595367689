<template>
  <v-layout>
    <div class="d-flex ml-3 pb-2 fill">
      <p class="ma-0 pt-1 initials">
        {{ document.initials }}
      </p>
      <div class="d-flex justify-space-between fill pr-3">
        <div>
          <v-icon>mdi-calendar</v-icon>
        </div>
        <p
          class="ma-0 pt-1"
          :class="{ valid: document.isValid, invalid: !document.isValid }"
        >
          {{ document.expiresAt }}
        </p>
      </div>
    </div>
  </v-layout>
</template>

<script>
  import { parseISO, addDays, compareAsc } from "date-fns";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "RecyclerRequiredDocuments",

    props: {
      requiredDocument: {
        type: Object,
        required: true
      }
    },

    mixins: [MasksMixin],

    computed: {
      document() {
        return {
          initials: this.requiredDocument.initials,
          expiresAt: this.formatDate(this.requiredDocument.expiresAt),
          isValid: this.dateIsValid(this.requiredDocument.expiresAt)
        };
      }
    },

    methods: {
      formatDate(date) {
        if (!date) {
          return "Não informado";
        }

        const dateISOFormat = parseISO(date);

        return this.parseDate(dateISOFormat, "dd/MM/yyyy");
      },

      dateIsValid(date) {
        if (!date) {
          return false;
        }
        const documentDate = addDays(new Date(date), 1);

        return compareAsc(documentDate, new Date()) !== -1;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .initials {
    min-width: 170px;
  }

  .valid {
    color: $situation-2;
  }

  .invalid {
    color: $situation-5;
  }
</style>
