<template>
  <div>
    <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
      <validation-observer>
        <v-form @submit.prevent="handleConfirm" slot-scope="{ invalid }">
          <v-card class="modal">
            <v-card-title class="modal-title"
              >Alteração de Endereço</v-card-title
            >
            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <div class="mx-7 mt-5">
                <h3 class="mb-4">Endereço</h3>
                <address-form :address="innerAddress" :isRequired="true" />
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 black-3--text cancel-button"
                color="#ffffff"
                :block="$isMobile"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                class="ma-0 mt-1 white--text next-step-button"
                color="secondary-color-1"
                :block="$isMobile"
                :disabled="invalid"
                >Atualizar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import AddressForm from "@/modules/core/views/components/AddressForm.vue";

  export default {
    name: "EditAddressDialog",

    props: {
      state: {
        type: Object,
        required: true
      },

      fnConfirm: {
        type: Function,
        required: true
      },

      address: {
        type: Object,
        required: true
      }
    },

    components: {
      ValidationObserver,
      AddressForm
    },

    data: () => ({
      innerAddress: {
        address: undefined,
        neighborhood: undefined,
        city: undefined,
        state: {
          id: undefined
        }
      }
    }),

    watch: {
      "state.dialog": function(newVal) {
        if (newVal) {
          this.onDialogOpen();
        }
      }
    },

    methods: {
      onDialogOpen() {
        this.innerAddress = this.copyAddress(this.address);
      },

      handleConfirm() {
        this.state.dialog = false;
        this.fnConfirm(this.copyAddress(this.innerAddress));
      },

      copyAddress(address) {
        return {
          ...address,
          state: {
            ...address.state
          }
        };
      }
    }
  };
</script>

<style></style>
