<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" scrollable :persistent="true" width="488">
      <validation-observer>
        <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
          <v-card class="modal">
            <v-card-title class="modal-title"
              >Alteração de dados do Representante</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <div class="mx-7 mt-5">
                <h3 class="mb-4">Informações do Representante</h3>
                <v-row dense>
                  <v-col cols="12">
                    <field
                      v-model="form.recyclerAgent"
                      type="genericText"
                      label="Nome"
                      name="name"
                      :isRequired="true"
                    />
                  </v-col>
                </v-row>
                <h3 class="mb-4">Contato</h3>
                <v-row dense>
                  <v-col cols="12">
                    <field
                      v-model="form.email"
                      type="email"
                      label="E-mail"
                      name="email"
                      :isRequired="true"
                    />
                  </v-col>
                </v-row>
                <phone-field v-model="form.phones" :isRequired="true" />
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 cancel-button black-3--text"
                color="#ffffff"
                :block="$isMobile"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                color="secondary-color-1"
                :block="$isMobile"
                class="ma-0 mt-1 next-step-button white--text"
                :disabled="invalid"
                >Atualizar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";

  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";

  export default {
    name: "EditRecyclerAgentDialog",

    props: {
      state: {
        type: Object,
        required: true
      },

      fnConfirm: {
        type: Function,
        required: true
      },

      agent: {
        type: Object,
        required: true
      }
    },

    components: {
      ValidationObserver,
      Field,
      PhoneField
    },

    data: () => ({
      form: {
        recyclerAgent: undefined,
        email: undefined,
        phones: [{}]
      }
    }),

    watch: {
      "state.dialog": function(newVal) {
        if (newVal) {
          this.onDialogOpen();
        }
      }
    },

    methods: {
      onDialogOpen() {
        this.form = {
          ...this.agent
        };
      },

      submitForm() {
        this.state.dialog = false;
        this.fnConfirm({
          ...this.form
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
