<template>
  <div v-if="state.dialog">
    <v-dialog scrollable persistent width="500" v-model="state.dialog">
      <validation-observer>
        <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
          <v-card>
            <v-card-title>Alteração de dados do Reciclador</v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <field
                    v-model="formData.name"
                    isRequired
                    type="genericText"
                    label="Nome"
                    name="name"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <field
                    v-model="formData.cnpj"
                    isRequired
                    type="cnpj"
                    label="CNPJ"
                    name="cnpj"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <field
                    v-model="formData.site"
                    type="genericText"
                    label="Site"
                    name="site"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    class="cancel-button black-3--text"
                    color="#ffffff"
                    @click="state.dialog = false"
                    >Cancelar</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    type="submit"
                    color="secondary-color-1"
                    class="next-step-button white--text"
                    :disabled="invalid"
                    height="56"
                    >Atualizar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";

  import Field from "@/modules/core/views/components/Field";

  export default {
    name: "EditRecyclerDialog",
    props: {
      state: {
        type: Object,
        required: true
      },
      fnConfirm: {
        type: Function,
        required: true
      },
      recycler: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      Field
    },
    data: () => ({
      formData: {
        name: null,
        cnpj: null,
        site: null
      }
    }),
    watch: {
      "state.dialog": function(newVal) {
        if (newVal) {
          this.onDialogOpen();
        }
      }
    },
    methods: {
      onDialogOpen() {
        this.formData = {
          name: this.recycler.name,
          cnpj: this.recycler.cnpj,
          site: this.recycler.site
        };
      },
      submitForm() {
        this.state.dialog = false;
        this.fnConfirm({
          ...this.formData
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
