<template>
  <div>
    <v-dialog v-model="state.dialog" :persistent="true" width="588">
      <v-card class="modal">
        <v-form @submit.prevent="submitForm">
          <v-card-title class="modal-title">Alteração de Resíduos</v-card-title>
          <v-divider></v-divider>

          <div class="px-5">
            <h3>Informe quais resíduos são recolhidos por essa empresa:</h3>
            <v-expansion-panels accordion flat class="mt-5">
              <expansion-panel v-for="(item, i) in residuesType" :key="i">
                <template slot="title">
                  <span
                    :class="{ 'with-previous': hasPreviusResidueType(item) }"
                    >{{ item.description }}
                  </span>
                </template>
                <template slot="content">
                  <v-layout class="flex-wrap pt-4 px-3">
                    <v-checkbox
                      v-for="(res, i) in item.residueSubtypes"
                      :key="i"
                      :multiple="true"
                      v-model="selectedResidues"
                      :label="res.description"
                      :value="{ id: res.id }"
                      class="ma-0 item"
                      dense
                    ></v-checkbox>
                  </v-layout>
                </template>
              </expansion-panel>
            </v-expansion-panels>
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#ffffff"
              :block="$isMobile"
              @click="state.dialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              class="ma-0 mt-1 white--text next-step-button"
              color="secondary-color-1"
              :block="$isMobile"
              @click="handleConfirm"
              >Atualizar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ExpansionPanel from "@/modules/core/views/components/ExpansionPanel";

  import { mapActions } from "vuex";

  export default {
    name: "EditResduesDialog",

    props: {
      state: {
        type: Object,
        required: true
      },

      fnConfirm: {
        type: Function,
        required: true
      },

      selectedIds: {
        type: Array,
        required: true
      }
    },

    components: {
      ExpansionPanel
    },

    data: () => ({
      selectedResidues: [],
      residuesType: [],
      residues: []
    }),

    async mounted() {
      await this.fetchResidues();
    },

    watch: {
      "state.dialog": function(newVal) {
        if (newVal) {
          this.onDialogOpen();
        }
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", ["getResidues"]),

      onDialogOpen() {
        this.selectedResidues = [...this.selectedIds];
      },

      async fetchResidues() {
        try {
          const { data } = await this.getResidues();

          this.residuesType = data;
        } catch (err) {
          this.toggleSnackbar({
            text: "Algo deu errado.",
            type: "error"
          });
        }
      },

      handleConfirm() {
        this.state.dialog = false;
        this.fnConfirm([...this.selectedResidues]);
      },

      hasPreviusResidueType(residueType) {
        return residueType.residueSubtypes.some(residue => {
          return this.selectedIds.some(
            selectedId => selectedId.id === residue.id
          );
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .item {
    min-width: 240px;
  }

  .with-previous {
    color: $secondary-color-1;
    font-weight: 450;
  }
</style>
